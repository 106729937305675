import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";

import AboutTeamMemberTemplate from "../../../templates/about/AboutTeamMemberTemplate";
import HtmlContent from "../../../components/helpers/HtmlContent";

export default function TeamMemberPage({data}) {

    const {name, post, photo, parent: {html}} = data["teamMember"];

    return (
        <>
            <Helmet>
                <title>{name}</title>
            </Helmet>
            <AboutTeamMemberTemplate
                name={name}
                post={post}
                photo={photo}>
                <HtmlContent content={html} />
            </AboutTeamMemberTemplate>
        </>
    );

}

export const query = graphql`
    query($id: String!) {
        teamMember(id: {eq: $id}) {
            name
            post
            photo {
                childImageSharp {
                    gatsbyImageData(
                        layout: CONSTRAINED,
                        width: 202,
                        height: 269,
                        sizes: "(min-width: 768px) 156px, 202px",
                        outputPixelDensities: [0.772, 1, 1.544, 2]
                    )
                }            
            }
            parent {
                ...on MarkdownRemark {
                    html
                }
            }
        }
    }
`;


