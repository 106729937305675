import React from "react";
import PropTypes from "prop-types";

import PreviewCompatibleImage from "../../components/helpers/PreviewCompatibleImage";

import * as classes from "./AboutTeamMemberTemplate.module.scss";

export default function AboutTeamMemberTemplate({name, post, photo, children}) {

    const nameParts = name.trim().split(/\s+/);

    return (
        <article>
            <div className={classes.card}>
                <PreviewCompatibleImage
                    className={classes.photo}
                    image={photo}
                    alt={name} />
                <div className={classes.cardContent}>
                    <h2>
                        {nameParts.length === 3 ?
                            <>
                                {nameParts[0]} {nameParts[1]} <br />
                                {nameParts[2]}
                            </> :
                            name}
                    </h2>
                    <p>{post}</p>
                </div>
            </div>
            {children}
        </article>
    );

}

AboutTeamMemberTemplate.propTypes = {
    name: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    photo: PropTypes.object.isRequired
};
